// Add your JS customizations here
jQuery(function ($) {
    $('#header-basket').click(function() {
        $('#basket-dropdown').toggleClass('open');
    });

    $('#basket-dropdown .basket-dropdown-close').click(function(e) {
        e.preventDefault();
        $('#basket-dropdown').removeClass('open');
    });

    $('#header-basket-mobile').click(function() {
        $('#basket-dropdown-mobile').toggleClass('open');
    });

    $('#basket-dropdown-mobile .basket-dropdown-close').click(function(e) {
        e.preventDefault();
        $('#basket-dropdown-mobile').removeClass('open');
    });

    jQuery(".product-categories-menu li a[data-toggle=dropdown]").click(function() {
        var url = jQuery(this).attr('href');
        window.location.href = url;
    });

    jQuery('#copy-btn').click(function(){
        // Select the text field
        var copyText = jQuery('#copy-link');

        // Select the text inside the text field
        copyText.select();
        copyText[0].setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        document.execCommand("copy");

        // Change the button text to 'Copied!'
        var button = jQuery(this);
        button.text('Copied!');

        // Set a delay to change the text back to 'Copy'
        setTimeout(function(){
            button.text('Copy');
        }, 1000);
    });

    // ps post desktop slider
    var ps_post_desktop_slider = UIkit.slider('.ps-post-product-list .ps-post-list div.desktop-slider');
    jQuery('.ps-post-product-list .ps-post-list .slider-prev').click(function(e) {
        e.preventDefault();
        ps_post_desktop_slider.show(ps_post_desktop_slider.index - 1);
    });
    jQuery('.ps-post-product-list .ps-post-list .slider-next').click(function(e) {
        e.preventDefault();
        ps_post_desktop_slider.show(ps_post_desktop_slider.index + 1);
    });

    // ps post mobile slider
    var ps_post_mobile_slider = UIkit.slider('.ps-post-product-list .ps-post-list div.mobile-slider');
    jQuery('.ps-post-product-list .ps-post-list .slider-prev').click(function(e) {
        e.preventDefault();
        ps_post_mobile_slider.show(ps_post_mobile_slider.index - 1);
    });
    jQuery('.ps-post-product-list .ps-post-list .slider-next').click(function(e) {
        e.preventDefault();
        ps_post_mobile_slider.show(ps_post_mobile_slider.index + 1);
    });

    // ps product desktop slider
    var ps_product_desktop_slider = UIkit.slider('.ps-post-product-list .ps-product-list div.desktop-slider');
    jQuery('.ps-post-product-list .ps-product-list .slider-prev').click(function(e) {
        e.preventDefault();
        ps_product_desktop_slider.show(ps_product_desktop_slider.index - 1);
    });
    jQuery('.ps-post-product-list .ps-product-list .slider-next').click(function(e) {
        e.preventDefault();
        ps_product_desktop_slider.show(ps_product_desktop_slider.index + 1);
    });

    // ps product mobile slider
    var ps_product_mobile_slider = UIkit.slider('.ps-post-product-list .ps-product-list div.mobile-slider');
    jQuery('.ps-post-product-list .ps-product-list .slider-prev').click(function(e) {
        e.preventDefault();
        ps_product_mobile_slider.show(ps_product_mobile_slider.index - 1);
    });
    jQuery('.ps-post-product-list .ps-product-list .slider-next').click(function(e) {
        e.preventDefault();
        ps_product_mobile_slider.show(ps_product_mobile_slider.index + 1);
    });

    // ps product mobile slider
    var hero_slider = UIkit.slider('.hero-banner div[uk-slider]');
    jQuery('.hero-banner .slider-prev').click(function(e) {
        e.preventDefault();
        hero_slider.show(hero_slider.index - 1);
    });
    jQuery('.hero-banner .slider-next').click(function(e) {
        e.preventDefault();
        hero_slider.show(hero_slider.index + 1);
    });

    // ps go to link
    $(document).ready(function() {
        $('.problem-solver-select select').on('change', function() {
            var selectedValue = $(this).val();
            $('#problem-solver-go-to-link').attr('href', selectedValue);
        });
    });

    // $(".page-title .breadcrumbs a[href*='/blog/']")
    // .attr("href", window.location.origin + "/problem-solver/")
    // .text("Cleaning Advice & Problem Solver");

    // checkbox
    $(document).ready(function($) {
        $('input[type="checkbox"].echo-form-field').add('input[type="checkbox"]').change(function() {
            var label = $(this).parent('label');
            if ($(this).is(':checked')) {
                label.addClass('checked-label');
            } else {
                label.removeClass('checked-label');
            }
        });
    });
    // Check the checkbox status on page load
    $('input[type="checkbox"].echo-form-field').each(function() {
        var label = $(this).parent('label');
        if ($(this).is(':checked')) {
            label.addClass('checked-label');
        } else {
            label.removeClass('checked-label');
        }
    });

    // radio
    $(document).ready(function($) {
        $('input[type="radio"].echo-form-field').change(function() {
            var label = $(this).parent('label');
            if ($(this).is(':checked')) {
                label.addClass('checked-label');
            } else {
                label.removeClass('checked-label');
            }
        });
    });
    // Check the radio status on page load
    $('input[type="radio"].echo-form-field').each(function() {
        var label = $(this).parent('label');
        if ($(this).is(':checked')) {
            label.addClass('checked-label');
        } else {
            label.removeClass('checked-label');
        }
    });

    // Function to update the class on the toggle based on the preference
    function updateVATToggle(vatPreference) {
        if (vatPreference === 'ex-vat') {
            $('#vat-toggle .toggle').addClass('ex-vat-active').removeClass('inc-vat-active');
            $('.price.inc-vat').addClass('uk-hidden');
            $('.price.ex-vat').removeClass('uk-hidden');
            $('.search-price.inc-vat').hide()
            $('.search-price.ex-vat').show();
            $('#aws-search-result-1').removeClass('inc-vat').addClass('ex-vat');
        } else if (vatPreference === 'inc-vat') {
            $('#vat-toggle .toggle').addClass('inc-vat-active').removeClass('ex-vat-active');
            $('.price.ex-vat').addClass('uk-hidden');
            $('.price.inc-vat').removeClass('uk-hidden');
            $('.search-price.ex-vat').hide();
            $('.search-price.inc-vat').show();
            $('#aws-search-result-1').removeClass('ex-vat').addClass('inc-vat');
        }
    }

    // Check the cookie on page load
    const vatPreference = Cookies.get('vat-preference-v2');
    if (vatPreference) {
        updateVATToggle(vatPreference);
    }

    // Handle the Ex VAT click
    $('#vat-toggle .ex-vat').on('click', function(e) {
        e.preventDefault(); // Prevent the default action
        Cookies.set('vat-preference-v2', 'ex-vat', { expires: 7 }); // Save preference in cookies for 7 days
        updateVATToggle('ex-vat');
    });

    // Handle the Inc VAT click
    $('#vat-toggle .inc-vat').on('click', function(e) {
        e.preventDefault(); // Prevent the default action
        Cookies.set('vat-preference-v2', 'inc-vat', { expires: 7 }); // Save preference in cookies for 7 days
        updateVATToggle('inc-vat');
    });

    // Handle the toggle click
    $('#vat-toggle .toggle').on('click', function(e) {
        e.preventDefault(); // Prevent the default action
        if ($(this).hasClass('inc-vat-active')) {
            Cookies.set('vat-preference-v2', 'ex-vat', { expires: 7 });
            updateVATToggle('ex-vat');
        } else {
            Cookies.set('vat-preference-v2', 'inc-vat', { expires: 7 });
            updateVATToggle('inc-vat');
        }
    });

    // Automatically trigger the click handler based on saved preference
    if (vatPreference) {
        // Programmatically trigger the corresponding click event
        if (vatPreference === 'ex-vat') {
            $('#vat-toggle .ex-vat').trigger('click');
        } else if (vatPreference === 'inc-vat') {
            $('#vat-toggle .inc-vat').trigger('click');
        }
    }

    $('#aws-search-result-1').addClass(vatPreference);

    // up sells products desktop slider
    var up_sells_desktop_slider = UIkit.slider('.up-sells.products div.desktop-slider');
    jQuery('.up-sell-products .slider-prev').click(function(e) {
        e.preventDefault();
        up_sells_desktop_slider.show(up_sells_desktop_slider.index - 1);
    });
    jQuery('.up-sell-products .slider-next').click(function(e) {
        e.preventDefault();
        up_sells_desktop_slider.show(up_sells_desktop_slider.index + 1);
    });

    // related products desktop slider
    var related_products_desktop_slider = UIkit.slider('.related.products div.desktop-slider');
    jQuery('.related-products .slider-prev').click(function(e) {
        e.preventDefault();
        related_products_desktop_slider.show(related_products_desktop_slider.index - 1);
    });
    jQuery('.related-products .slider-next').click(function(e) {
        e.preventDefault();
        related_products_desktop_slider.show(related_products_desktop_slider.index + 1);
    });

    // featured products desktop slider
    var featured_products_desktop_slider = UIkit.slider('.featured-products div.desktop-slider');
    jQuery('.featured-products .slider-prev').click(function(e) {
        e.preventDefault();
        featured_products_desktop_slider.show(featured_products_desktop_slider.index - 1);
    });
    jQuery('.featured-products .slider-next').click(function(e) {
        e.preventDefault();
        featured_products_desktop_slider.show(featured_products_desktop_slider.index + 1);
    });

    // latest blog desktop slider
    var latest_blogs_desktop_slider = UIkit.slider('.latest-from-our-blog div.desktop-slider');
    jQuery('.latest-from-our-blog .slider-prev').click(function(e) {
        e.preventDefault();
        latest_blogs_desktop_slider.show(latest_blogs_desktop_slider.index - 1);
    });
    jQuery('.latest-from-our-blog .slider-next').click(function(e) {
        e.preventDefault();
        latest_blogs_desktop_slider.show(latest_blogs_desktop_slider.index + 1);
    });

    // latest blog mobile slider
    var latest_blogs_mobile_slider = UIkit.slider('.latest-from-our-blog div.mobile-slider');
    jQuery('.latest-from-our-blog .slider-prev').click(function(e) {
        e.preventDefault();
        latest_blogs_mobile_slider.show(latest_blogs_mobile_slider.index - 1);
    });
    jQuery('.latest-from-our-blog .slider-next').click(function(e) {
        e.preventDefault();
        latest_blogs_mobile_slider.show(latest_blogs_mobile_slider.index + 1);
    });

    // customer reviews slider
    var customer_reviews_slider = UIkit.slider('.customer-reviews div.desktop-slider');
    jQuery('.customer-reviews .slider-prev').click(function(e) {
        e.preventDefault();
        customer_reviews_slider.show(customer_reviews_slider.index - 1);
    });
    jQuery('.customer-reviews .slider-next').click(function(e) {
        e.preventDefault();
        customer_reviews_slider.show(customer_reviews_slider.index + 1);
    });

    // header scroll active
    let lastScrollTop = 0;
    const scrollThreshold = 100;
    const minScrollChange = 30; // Set the threshold to 30 pixels

    // jQuery(window).on('scroll', function () {
    //     let scrollTop = jQuery(this).scrollTop();

    //     if (Math.abs(scrollTop - lastScrollTop) > minScrollChange) { // Ignore changes less than 30px
    //         if (scrollTop > scrollThreshold && scrollTop > lastScrollTop) {
    //             jQuery('header').addClass('scroll-down');
    //         } else {
    //             jQuery('header').removeClass('scroll-down');
    //         }
    //         lastScrollTop = scrollTop;
    //     }
    // });

    var lastScrollTopdesk = 0;
    var headerdesk = document.getElementById('header');

    window.addEventListener('scroll', function() {
        var currentScrolldesk = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScrolldesk > lastScrollTopdesk) {
            // Scroll down
            headerdesk.classList.add('hidedesk');			
        } else {
            // Scroll up
            headerdesk.classList.remove('hidedesk');			
        }
        lastScrollTopdesk = currentScrolldesk <= 0 ? 0 : currentScrolldesk; // For Mobile or negative scrolling
    });

    // Custom JavaScript to handle + and - button functionality
    $(document).ready(function() {
        // Handle minus button click
        $(document).on('click', '.qty-minus', function() {
            var $input = $(this).next('input');
            var currentValue = parseInt($input.val());
            if (currentValue > 1) {
                $input.val(currentValue - 1);
            } else {
                $input.val(0); // Set to 0
            }
        });
    
        // Handle plus button click
        $(document).on('click', '.qty-plus', function() {
            var $input = $(this).prev('input');
            var currentValue = parseInt($input.val());
            $input.val(currentValue + 1);
        });
    });

    // $(document).ready(function() {

    //     // Listen for any cart updates
    //     $(document.body).on('added_to_cart wc_fragments_refreshed', function () {
    //         updateCustomCart();
    //     });
    
    //     // Function to update the custom cart via AJAX
    //     function updateCustomCart() {
    //         $.ajax({
    //             url: woocommerce_params.ajax_url, // WooCommerce's AJAX URL
    //             type: 'POST',
    //             data: {
    //                 action: 'update_custom_cart'
    //             },
    //             success: function (response) {
    //                 $('#custom-cart-content').html(response.cart_content); // Replace cart content
    //                 $('.basket-qty-number').html(response.cart_quantity);
    //             },
    //             error: function () {
    //                 console.log('Failed to update the cart.');
    //             }
    //         });
    //     }
    // });
    
    $(document).ready(function($) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("add-to-cart")) {
            let productId = urlParams.get("add-to-cart");
            
            // Trigger your custom JavaScript action here
            // console.log("Product added to cart:", productId);
            
            // Example: Show an alert
            // alert("Product " + productId + " was added to the cart!");

            $('#basket-dropdown').addClass('open');

            setTimeout(function () {
                $('#basket-dropdown').removeClass('open');
            }, 5000); // 3000 milliseconds = 5 seconds
        }
    });

    $("body.logged-in").find('.header-menu #menu-item-37657').hide();
    $("body.logged-in").find('.header-menu #menu-item-61 a').text('My Account');
});